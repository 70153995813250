import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Hero from "../components/hero";
import Features1 from "../components/features1";
import CTA from "../components/cta";
import Features2 from "../components/features2";
import Steps from "../components/steps";
import Partners from "../components/partners";
import Contact from "../components/contact";
import Footer from "../components/footer";
import "./home.css";

const Home = (props) => {
	return (
		<div className="home-container">
			<Helmet>
				<title>Valant</title>
			</Helmet>
			<Navbar></Navbar>
			<Hero></Hero>
			<CTA></CTA>
			<Features2></Features2>
			<Steps></Steps>
			<Partners></Partners>
			<Contact></Contact>
			<Footer></Footer>
		</div>
	);
};

export default Home;
