import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./steps.css";

import partneri from "../images/partneriGrupa.svg";
import partneriMobile from "../images/valantPartneriMobile.svg";

const Steps = (props) => {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	useEffect(() => {
		const handleResize = () => setIsMobile(window.innerWidth <= 768);
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div style={{ width: "100%", height: "auto" }}>
			<img
				alt="Partneri"
				src={isMobile ? partneriMobile : partneri}
				style={{
					width: isMobile ? "100%" : "80%",
					height: "auto",
					objectFit: "contain",
					margin: "0 auto",
				}}
			/>
		</div>
	);
};

Steps.defaultProps = {
	step1Description:
		"Schedule a consultation with our experts to discuss your road painting needs.",
	step3Description:
		"Customize the barriers to fit your specific requirements and preferences.",
	step2Title: "Product Selection",
	step2Description:
		"Choose from our range of high-quality road painting barriers and products.",
	step1Title: "Consultation",
	step3Title: "Customization",
	step4Description:
		"Our team will efficiently install the road painting barriers at your desired location.",
	step4Title: "Installation",
};

Steps.propTypes = {
	step1Description: PropTypes.string,
	step3Description: PropTypes.string,
	step2Title: PropTypes.string,
	step2Description: PropTypes.string,
	step1Title: PropTypes.string,
	step3Title: PropTypes.string,
	step4Description: PropTypes.string,
	step4Title: PropTypes.string,
};

export default Steps;
