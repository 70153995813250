import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./features2.css";

import ojacanja from "../images/ojacanja.JPG";
import oktagonalne from "../images/oktagonalne.jpg";
import varene from "../images/varene.jpg";

const Features2 = (props) => {
	const [activeTab, setActiveTab] = useState(0);
	const totalTabs = 3;

	useEffect(() => {
		const interval = setInterval(() => {
			setActiveTab((prevTab) => (prevTab + 1) % totalTabs);
		}, 3000);

		return () => clearInterval(interval); // Clear interval on component unmount
	}, [totalTabs]);

	return (
		<div className="thq-section-padding">
			<div className="features2-container2 thq-section-max-width">
				<div className="features2-tabs-menu">
					<div
						onClick={() => setActiveTab(0)}
						className="features2-tab-horizontal1"
					>
						<div className="features2-divider-container1">
							{activeTab === 0 && <div className="features2-container3"></div>}
						</div>
						<div className="features2-content1">
							<h2 className="thq-heading-2">{props.feature1Title}</h2>
							<span className="thq-body-small">
								{props.feature1Description}
							</span>
						</div>
					</div>
					<div
						onClick={() => setActiveTab(1)}
						className="features2-tab-horizontal2"
					>
						<div className="features2-divider-container2">
							{activeTab === 1 && <div className="features2-container4"></div>}
						</div>
						<div className="features2-content2">
							<h2 className="thq-heading-2">{props.feature2Title}</h2>
							<span className="thq-body-small">
								{props.feature2Description}
							</span>
						</div>
					</div>
					<div
						onClick={() => setActiveTab(2)}
						className="features2-tab-horizontal3"
					>
						<div className="features2-divider-container3">
							{activeTab === 2 && <div className="features2-container5"></div>}
						</div>
						<div className="features2-content3">
							<h2 className="thq-heading-2">{props.feature3Title}</h2>
							<span className="thq-body-small">
								{props.feature3Description}
							</span>
						</div>
					</div>
				</div>
				<div className="features2-image-container">
					{activeTab === 0 && (
						<img
							alt={props.feature1ImgAlt}
							src={props.feature1ImgSrc}
							className="features2-image1"
							style={{ width: "100%", height: "auto" }}
						/>
					)}
					{activeTab === 1 && (
						<img
							alt={props.feature2ImgAlt}
							src={props.feature2ImgSrc}
							className="features2-image2"
							style={{ width: "100%", height: "auto" }}
						/>
					)}
					{activeTab === 2 && (
						<img
							alt={props.feature3ImgAlt}
							src={props.feature3ImgSrc}
							className="features2-image3"
							style={{ width: "100%", height: "auto" }}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

Features2.defaultProps = {
	feature1ImgAlt: "Road painting image",
	feature3ImgAlt: "Professional team image",
	feature2Title: "Varene cijevi",
	feature1Title: "Ojačanja za PVC stolariju",
	feature1ImgSrc: ojacanja,
	feature3ImgSrc: oktagonalne,
	feature3Title: "Oktagonalne cijevi za rolo sisteme",
	feature2ImgAlt: "Barrier products image",
	feature2ImgSrc: varene,
};

Features2.propTypes = {
	feature3Description: PropTypes.string,
	feature1ImgAlt: PropTypes.string,
	feature1Description: PropTypes.string,
	feature3ImgAlt: PropTypes.string,
	feature2Title: PropTypes.string,
	feature1Title: PropTypes.string,
	feature1ImgSrc: PropTypes.string,
	feature3ImgSrc: PropTypes.string,
	feature2Description: PropTypes.string,
	feature3Title: PropTypes.string,
	feature2ImgAlt: PropTypes.string,
	feature2ImgSrc: PropTypes.string,
};

export default Features2;
