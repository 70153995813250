import React from "react";

import Script from "dangerous-html/react";
import PropTypes from "prop-types";

import image1 from "../images/valant1.jpeg";
import image2 from "../images/valant2.jpg";
import image3 from "../images/valant3.jpg";
import image4 from "../images/valant4.jpg";
import image5 from "../images/valant5.jpeg";
import image6 from "../images/valant6.jpg";
import image7 from "../images/valant7.jpg";

import logo from "../images/valantLogo.png";

import "./hero.css";

const Hero = (props) => {
	return (
		<div className="hero-header78">
			<div className="hero-column thq-section-padding thq-section-max-width">
				<div className="hero-content1">
					<img
						alt={"Logo"}
						src={logo}
						className="hero-placeholder-imagelogo thq-img-scale thq-img-ratio-2-1"
					/>
					<p className="hero-text2 thq-body-large">{props.content1}</p>
				</div>
				{/* <div className="hero-actions">
					<button className="thq-button-filled hero-button1">
						<span className="thq-body-small">{props.action1}</span>
					</button>
					<button className="thq-button-outline hero-button2">
						<span className="thq-body-small">{props.action2}</span>
					</button>
				</div> */}
			</div>
			<div className="hero-content2">
				<div className="hero-row-container1 thq-animated-group-container-horizontal thq-mask-image-horizontal">
					<div className="thq-animated-group-horizontal">
						<img
							alt={props.image1Alt}
							src={image1}
							className="hero-placeholder-image10 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image2Alt}
							src={image2}
							className="hero-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image3Alt}
							src={image3}
							className="hero-placeholder-image12 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image4Alt}
							src={image4}
							className="hero-placeholder-image13 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image5Alt}
							src={image5}
							className="hero-placeholder-image14 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image6Alt}
							src={image6}
							className="hero-placeholder-image15 thq-img-scale thq-img-ratio-1-1"
						/>
					</div>
					<div className="thq-animated-group-horizontal">
						<img
							alt={props.image1Alt}
							src={image7}
							className="hero-placeholder-image16 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image2Alt}
							src={image3}
							className="hero-placeholder-image17 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image3Alt}
							src={image5}
							className="hero-placeholder-image18 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image4Alt}
							src={image7}
							className="hero-placeholder-image19 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image5Alt}
							src={image1}
							className="hero-placeholder-image20 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image5Alt}
							src={image4}
							className="hero-placeholder-image21 thq-img-scale thq-img-ratio-1-1"
						/>
					</div>
				</div>
				{/* <div className="hero-row-container2 thq-animated-group-container-horizontal thq-mask-image-horizontal">
					<div className="thq-animated-group-horizontal-reverse">
						<img
							alt={props.image7Alt}
							src={props.image7Src}
							className="hero-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image8Alt}
							src={props.image8Src}
							className="hero-placeholder-image23 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image9Alt}
							src={props.image9Src}
							className="hero-placeholder-image24 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image10Alt}
							src={props.image10Src}
							className="hero-placeholder-image25 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image11Alt}
							src={props.image11Src}
							className="hero-placeholder-image26 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image12Alt}
							src={props.image12Src}
							className="hero-placeholder-image27 thq-img-scale thq-img-ratio-1-1"
						/>
					</div>
					<div className="thq-animated-group-horizontal-reverse">
						<img
							alt={props.image7Alt}
							src={props.image7Src}
							className="hero-placeholder-image28 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image8Alt}
							src={props.image8Src}
							className="hero-placeholder-image29 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image9Alt}
							src={props.image9Src}
							className="hero-placeholder-image30 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image10Alt}
							src={props.image10Src}
							className="hero-placeholder-image31 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt={props.image11Alt}
							src={props.image11Src}
							className="hero-placeholder-image32 thq-img-scale thq-img-ratio-1-1"
						/>
						<img
							alt="Hero Image"
							src="https://images.unsplash.com/photo-1568214379698-8aeb8c6c6ac8?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHxncmFmaWN8ZW58MHx8fHwxNzE1Nzk0OTk5fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
							className="hero-placeholder-image33 thq-img-scale thq-img-ratio-1-1"
						/>
					</div>
				</div> */}
			</div>
			<div>
				<div className="hero-container2">
					<Script
						html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
					></Script>
				</div>
			</div>
		</div>
	);
};

Hero.defaultProps = {
	image3Src: "../",
	image8Alt: "Hero Image",
	image2Src:
		"https://images.unsplash.com/photo-1489278353717-f64c6ee8a4d2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjg1NTQ2N3w&ixlib=rb-4.0.3&q=80&w=1080",
	image6Alt: "Hero Image",
	image11Src:
		"https://images.unsplash.com/photo-1579541764764-6401d4100dd7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjg1NTQ2N3w&ixlib=rb-4.0.3&q=80&w=1080",
	image5Alt: "Hero Image",
	image1Alt: "Road painting equipment",
	image7Src:
		"https://images.unsplash.com/photo-1578301978069-45264734cddc?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjg1NTQ2OHw&ixlib=rb-4.0.3&q=80&w=1080",
	image7Alt: "Hero Image",
	image12Alt: "Hero Image",
	image2Alt: "Professional road painters at work",
	image6Src:
		"https://images.unsplash.com/photo-1605201470550-f107ba6fdad3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjg1NTQ2OHw&ixlib=rb-4.0.3&q=80&w=1080",
	image12Src:
		"https://images.unsplash.com/photo-1432888622747-4eb9a8efeb07?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjg1NTQ2OHw&ixlib=rb-4.0.3&q=80&w=1080",
	image3Alt: "Hero Image",
	image9Src:
		"https://images.unsplash.com/photo-1461723198950-3f65e13bb031?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjg1NTQ2OXw&ixlib=rb-4.0.3&q=80&w=1080",
	image11Alt: "Hero Image",
	action2: "Pošalji upit",
	action1: "Kontakt",
	image8Src:
		"https://images.unsplash.com/photo-1469504512102-900f29606341?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjg1NTQ2OXw&ixlib=rb-4.0.3&q=80&w=1080",
	image5Src:
		"https://images.unsplash.com/photo-1599601821513-00e3f9fa1e6a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjg1NTQ2OXw&ixlib=rb-4.0.3&q=80&w=1080",
	image4Src:
		"https://images.unsplash.com/photo-1556228578-f9707385e031?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjg1NTQ2OXw&ixlib=rb-4.0.3&q=80&w=1080",
	image10Alt: "Hero Image",
	image4Alt: "Hero Image",
	heading1: "VALANT",
	content1: "Distributer metalnih ojačanja za PVC stolariju",
	image10Src:
		"https://images.unsplash.com/photo-1529070538774-1843cb3265df?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjg1NTQ3MHw&ixlib=rb-4.0.3&q=80&w=1080",
	image9Alt: "Hero Image",
	image1Src:
		"https://images.unsplash.com/photo-1583119912267-cc97c911e416?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNjg1NTQ3MHw&ixlib=rb-4.0.3&q=80&w=1080",
};

Hero.propTypes = {
	image3Src: PropTypes.string,
	image8Alt: PropTypes.string,
	image2Src: PropTypes.string,
	image6Alt: PropTypes.string,
	image11Src: PropTypes.string,
	image5Alt: PropTypes.string,
	image1Alt: PropTypes.string,
	image7Src: PropTypes.string,
	image7Alt: PropTypes.string,
	image12Alt: PropTypes.string,
	image2Alt: PropTypes.string,
	image6Src: PropTypes.string,
	image12Src: PropTypes.string,
	image3Alt: PropTypes.string,
	image9Src: PropTypes.string,
	image11Alt: PropTypes.string,
	action2: PropTypes.string,
	action1: PropTypes.string,
	image8Src: PropTypes.string,
	image5Src: PropTypes.string,
	image4Src: PropTypes.string,
	image10Alt: PropTypes.string,
	image4Alt: PropTypes.string,
	heading1: PropTypes.string,
	content1: PropTypes.string,
	image10Src: PropTypes.string,
	image9Alt: PropTypes.string,
	image1Src: PropTypes.string,
};

export default Hero;
