import React from "react";

import PropTypes from "prop-types";

import "./steps.css";

const Steps = (props) => {
	return (
		<div className="steps-container1 thq-section-padding">
			<div className="steps-max-width thq-section-max-width">
				<div className="steps-container2 thq-grid-2">
					<div className="steps-section-header">
						<h2 className="thq-heading-2">O nama</h2>
						<p className="thq-body-large">
							VALANT d.o.o. je tvrtka locirana u mjestu Grude, BiH. Primarna
							djelatnost je distribucija metalnih ojačanja za PVC stolariju. Uz
							ojačanja za PVC stolariju u svojoj ponudi imamo više dimenzija
							pocinčanih cijevi za razne primjene kao i dvije dimenzije oktago-
							nalnih cijevi za rolo sisteme. Odlikuje nas dinamični rast
							zahvalju- jući timu sa dva desetljeća iskustva u ovoj industriji.
							Svi artikli su proizvedeni od pocinčanog lima gradacije DX51D sa
							slojem cinka od 100-200 g/m2 te kao dokaz tome možemo dostaviti
							svu potrebnu dokumentaciju i ateste. Sva ojačanja su podložna
							europskim standardima te pružamo potpunu garanciju na ojačanja
							kupljena kod nas. Ono što nas izdvaja je naša predanost praćenju
							novih sustava na tržištu te nam to omogućava zadovoljenje vaših
							evoluirajućih potreba i zahtjeva. Snažno vjerujemo u razvijanje
							snažnih i dugoročnih partnerstava s našim klijentima, težimo biti
							vaš pouzdani saveznik u postizanju uspjeha. S nestrpljenjem
							očekujemo priliku za suradnju s Vama.
						</p>
						{/* <div className="steps-actions">
							<button className="thq-button-animated thq-button-filled steps-button">
								<span className="thq-body-small">Main action</span>
							</button>
						</div> */}
					</div>
					<div className="steps-container3">
						<div className="steps-container4 thq-card">
							<h2 className="thq-heading-2">{props.step1Title}</h2>
							<span className="steps-text14 thq-body-small">
								{props.step1Description}
							</span>
						</div>
						<div className="steps-container5 thq-card">
							<h2 className="thq-heading-2">{props.step2Title}</h2>
							<span className="steps-text17 thq-body-small">
								{props.step2Description}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Steps.defaultProps = {
	step1Description:
		"Profesionalnost osigurava kvalitetu i povjerenje u svemu što radimo, ključna je za izgradnju snažnih i održivih odnosa.",
	step3Description:
		"Naš tim osigurava brze i pouzdane odgovore, s jasnom predanošću vašim potrebama.",
	step2Title: "Velika ponuda",
	step2Description:
		"Nudimo širok izbor visokokvalitetnih proizvoda, uz brzu isporuku i pouzdanu uslugu.",
	step1Title: "Profesionalnost",
	step3Title: "Customization",
	step4Description:
		"Our team will efficiently install the road painting barriers at your desired location.",
	step4Title: "Installation",
};

Steps.propTypes = {
	step1Description: PropTypes.string,
	step3Description: PropTypes.string,
	step2Title: PropTypes.string,
	step2Description: PropTypes.string,
	step1Title: PropTypes.string,
	step3Title: PropTypes.string,
	step4Description: PropTypes.string,
	step4Title: PropTypes.string,
};

export default Steps;
