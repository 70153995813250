import React, { useState } from "react";
import PropTypes from "prop-types";
import logo from "../images/ValantLogoNoDoo.png";
import "./footer.css";

const Footer = (props) => {
	// State for email input and message feedback
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");

	// Email validation regex
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	// Handle email submission
	const handleEmailSubmit = () => {
		if (emailRegex.test(email)) {
			setMessage("Email uspješno zaprimljen");
			// Here, you can add logic to actually handle the email, e.g., API call
			setEmail(""); // Clear input after successful submission
		} else {
			setMessage("Molimo ukucajte valjanu email adresu!");
		}
	};

	return (
		<footer className="footer-footer1 thq-section-padding">
			<div className="footer-max-width thq-section-max-width">
				<div className="footer-content">
					<div className="footer-newsletter">
						<img alt="Valant logo" src={logo} className="footer-image1" />
						<span className="thq-body-small">
							Ostavite svoj email kako biste prvi saznali najnovije vijesti o
							aktualnim cijenama i novim proizvodima.
						</span>
						<div className="footer-actions">
							<div className="footer-form">
								<div className="footer-container">
									<input
										type="email"
										placeholder="Upišite svoj email"
										className="footer-text-input thq-input"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
								<button
									className="thq-button-outline footer-button"
									onClick={handleEmailSubmit}
								>
									<span className="thq-body-small">{props.action1}</span>
								</button>
							</div>
							<span className="footer-message thq-body-small">{message}</span>
							<span className="footer-content2 thq-body-small">
								{props.content2}
							</span>
						</div>
					</div>
					<div className="footer-links">
						<div className="footer-column2">
							<strong className="thq-body-large footer-column2-title">
								{props.column2Title}
							</strong>
							<div className="footer-footer-links2">
								<a
									href="https://example.com"
									target="_blank"
									rel="noreferrer noopener"
									className="thq-body-small"
								>
									{props.link6}
								</a>
								<a
									href="https://example.com"
									target="_blank"
									rel="noreferrer noopener"
									className="thq-body-small"
								>
									{props.link7}
								</a>
							</div>
						</div>
						<div className="footer-column3">
							<strong className="thq-body-large footer-social-link1-title">
								{props.socialLinkTitleCategory}
							</strong>
							<div className="footer-social-links">
								<a href="https://www.linkedin.com/company/valant-d-o-o">
									<div className="footer-link17">
										<svg viewBox="0 0 877.714 1024" className="thq-icon-small">
											<path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
										</svg>
										<span className="thq-body-small">LinkedIn</span>
									</div>
								</a>
								{/* 
								<div className="footer-link16">
									<svg viewBox="0 0 950.857 1024" className="thq-icon-small">
										<path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
									</svg>
									<span className="thq-body-small">X</span>
								</div> */}
							</div>
						</div>
					</div>
				</div>
				<div className="footer-credits">
					<div className="thq-divider-horizontal"></div>
				</div>
			</div>
		</footer>
	);
};

Footer.defaultProps = {
	column2Title: "Tvrtka",
	link7: "Privacy Policy",
	link5: "Careers",
	action1: "Ostavite svoj email",
	content3: "Designed by VKDesigns",
	link4: "Blog",
	logoSrc: "https://presentation-website-assets.teleporthq.io/logos/logo.png",
	content2: "© 2024 Valant. All rights reserved.",
	link6: "Terms of Service",
	logoAlt: "Valant Logo",
	link1: "About Us",
	socialLinkTitleCategory: "Zapratite nas",
};

Footer.propTypes = {
	column2Title: PropTypes.string,
	link7: PropTypes.string,
	link5: PropTypes.string,
	action1: PropTypes.string,
	content3: PropTypes.string,
	link4: PropTypes.string,
	logoSrc: PropTypes.string,
	content2: PropTypes.string,
	link6: PropTypes.string,
	logoAlt: PropTypes.string,
	link1: PropTypes.string,
	socialLinkTitleCategory: PropTypes.string,
};

export default Footer;
